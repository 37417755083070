<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Breadcrumbs",
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  computed: {
    breadcrumbsWithoutLast() {
      return this.breadcrumbs.slice(0, -1)
    },
    lastBreadcrumb() {
      return this.breadcrumbs[this.breadcrumbs.length - 1]
    }
  }
})
</script>

<template>
  <div class="border-b border-gray-200 dark:border-zinc-700">
    <nav aria-label="Breadcrumb" class="mx-auto px-4 sm:px-6 lg:px-8">
      <ol role="list" class="flex items-center space-x-4 py-4">
        <li v-for="breadcrumb in breadcrumbsWithoutLast" :key="breadcrumb.id">
          <div class="flex items-center">
            <NuxtLink :href="breadcrumb.href" class="mr-4 text-sm font-medium text-gray-900 hover:text-gray-500 dark:text-gray-300 cursor-pointer dark:hover:text-gray-400">{{ breadcrumb.name }}</NuxtLink>
            <svg viewBox="0 0 6 20" aria-hidden="true" class="h-5 w-auto text-gray-300 dark:text-white/10">
              <path d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z" fill="currentColor" />
            </svg>
          </div>
        </li>
        <li class="text-sm">
          <NuxtLink :href="lastBreadcrumb.url" aria-current="page" class="font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 cursor-pointer	dark:hover:text-gray-500">{{ lastBreadcrumb.name }}</NuxtLink>
        </li>
      </ol>
    </nav>
  </div>
</template>

<style scoped>

</style>